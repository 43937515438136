.profile_settings {
  width: 100%;
  height: calc(100vh - 150px);
}

.profile_card_bg {
  display: flex;
  padding: 55px 50px;
  background-color: rgba(25, 118, 210, 0.15);
  height: 125px;
  /* background-color: #e3f2fd; */
}

.avatar {
  border: 4px solid #fff;
}

.f1x0m3f5 {
  font-size: 44px !important;
}

.profile_settings_body {
  margin-top: 60px;
  display: flex;
  justify-content: space-between;
}

.profile_view {
  display: grid;
  grid-template-columns: 1fr 3fr;
  gap: 20px;
  padding: 10px 0px;
  width: 30vw
}

.outlet_body {
  width: calc(100vw - 375px);
}

.action_buttons {
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  margin: 10px 0;
}

.profile_content {
  display: flex;
  justify-content: space-between;
}

.form_error_msg {
  color: red;
  font-weight: bold;
}
.back_button {
  margin: 10px 0;
}