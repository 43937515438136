.login-main-body {
  width: 100%;
  height: 100vh;
  display: grid;
  /* grid-template-columns: 1fr 1fr; */
  place-items: center;
  position: relative;
}

.login-footer-message {
  position: absolute;
  bottom: 24px;
  text-align: center;
}
.footer_color {
  color: var(--button-color);
  font-weight: 1000;
  font-size: 17px;
}
.red-text {
  color: red;
}
.footer_link {
  color: #000;
  font-weight: 600;
  margin: 8px;
}

.login-left {
  width: 100%;
  height: 100%;
  padding: 100px 40px;
  background-color: rgb(249, 249, 249);
}

.login-form-body {
  display: grid;
  gap: 10px;
}

.login-avatar-wrapper {
  margin-bottom: 20px;
  display: grid;
  place-items: center;
}

.welcome {
  font-size: 20px;
}

.login {
  font-weight: 800;
}

.login-header {
  margin-top: 10px;
  margin-left: 20px;
  font-size: 20px;
}

.login-header-bold {
  font-weight: 600;
}

.checkbox-wrapper {
  margin-bottom: 10px;
  display: flex;
  gap: 30px;
  align-items: center;
}

.forgot {
  color: rgb(0, 162, 255);
}
