

.menu-form-wrapper {
  display: grid;
  grid-template-columns: 1fr 11fr;
  padding: 0 5px;
  margin: 0 0 10px;
  gap: 15px;
}
.system_container {
  overflow: auto;
  height: calc(100vh - 285px);
}
.letter-container{
  background-color: #f0f0f0;
  height: calc(100vh - 285px);
    width:100%;
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 25px;
}
.email-template{
  background-color: #f0f0f0;
  height: calc(100vh - 285px);
    width:100%;
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 25px;
}
.tabs{
  display: flex;
  flex-direction: row;
  gap: 5px;
  line-height: 30px;
}
.fonts{
  font-size: 16px;
}
.heading{
  font-size: 16px;
  font-weight: 600;
}
.icons{
  display: flex;
  flex-direction: row;
  gap: 15px;
  line-height: 46px;
}
.popup-container{
  display: flex;
  flex-direction: row;
  gap: 48px;
  align-items: center;
  justify-content: center;
}
.tables{
  height: 280px;
  width: 250px;
  border: 1px solid rgb(146, 127, 127);

}
.main-container{
  display: flex;
  flex-direction: column;
  gap: 15px;
}
/* Hover effect for the table name */
.tables span:hover {
  background-color: #f0f0f0;  /* Change background color on hover */
  font-weight: bold;  /* Make text bold on hover */
}

.btn{
  display: flex;
  gap: 15px;
  flex-direction: row;
  justify-content: flex-end;
}
.btn-font{
  font-size: 13.5px;
  font-weight: 500;
  color: rgb(33, 83, 84);
}
.btns{
  display: flex;
  gap: 5px;
flex-direction: row;
justify-content: flex-end;
}
.mui-table{
  font-weight: 600;
  font-size: 15px;
}
