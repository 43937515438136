.header-body {
  background-color: var(--brand-color);
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: space-between;
  color: white;
  padding: 10px 20px;
  position: fixed;
  z-index: 3;
}

.header-right-area {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px
}
.logo {
  display: flex;
  justify-content: center;
  align-items: center;
}
.logo img {
  height: 30px;
}
.items-center {
  display: flex;
  justify-content: center;
  align-items: center;
}