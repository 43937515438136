.utilities-controller {
  display: flex;
}

.breadcrumb {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6;
}

.table-in-popup {
  padding: 10px 0;
}

.send_email {
  padding: 20px;
}
.content {
  width: 100%;
}
.popup-background {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
}

.history-data-popup {
  width: 300px;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
}

.client_button {
  display: inline-flex;
  justify-content: flex-start !important;
  width: 30%;
  border: 2px solid black;
  font-weight: 500 !important;
}
.badge {
  margin-left: 10px;
  background-color: #fff !important;
  color: #000 !important;
  border: 1px solid #ccc !important;
  border-radius: 50%;
  padding: 8px;
  display: inline-flex;
  align-items: "center";
  justify-content: center;
  font-size: 12px;
}
.send_mail_client {
  display: grid;
  grid-template-columns: 1fr 7fr;
  gap: 10px;
}
.custom_accordion {
  border: 1px solid #ccc !important;
  padding: 10px !important;
  border-radius: 10px !important;
  height: fit-content;
}
.send_email .ql-container.ql-snow {
  border: none !important;
  padding: 0px !important;
}
.send_email .ql-editor {
  padding: 0px !important;
}
.file_upload_list {
  display: flex;
  gap: 10px;
  align-items: center;
  margin: 10px 0 0;
}
.file_upload_detail {
  display: flex;
  gap: 10px;
  border: 1px solid #ccc;
  padding: 7px 10px;
  border-radius: 6px;
  align-items: center;
}
.mail_status {
  display: flex;
  align-items: center;
  gap: 10px;
}
.action_buttons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
}

.extract-form {
  background-color: rgb(249, 249, 249);
  padding: 10px;
  margin: 12px;
  min-height: 28vh;
}


.custom-tree {
  max-height: 300px; /* Matches the dropdown height */
  overflow-y: auto;  /* Enable scrolling for the tree as well */
}

