.underline-on-hover {
  text-decoration: none; /* Remove default underline */
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.underline-on-hover:hover {
  color: #0f6cbd; /* Change font color on hover */
}

.underline-on-hover:hover::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -1px; /* Adjust the distance from the text */
  width: 100%;
  height: 1px; /* Adjust the thickness of the underline */
  background-color: #0f6cbd; /* Adjust the color of the underline */
}
