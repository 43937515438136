.month-annual-grid {
  padding-top: 5px;
  display: grid;
  row-gap: 0.5em;
  column-gap: 0.5em;
  grid-template-columns: 1fr 8fr;
  /* place-items: center; */
}

.table-value,
.table-value-income {
  max-width: 500px;
  display: flex;
  justify-content: space-between;
}

.table-value-income {
  max-width: 200px;
}

.table-heading {
  padding: 5px 0;
  border-bottom: 1px solid #bdbdbd;
}

.table-subhead,
.table-heading {
  font-weight: 700;
}

.outgoing-wrapper {
  max-width: 700px;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.heading-label-only {
  display: grid;
  place-items: center;
}
.table-wrapper-in-container {
  padding: 10px 20px;
}
