.floating-timer {
  background: rgb(87 78 78);
  border: 1px solid #ddd;
  border-radius: 6px;
  padding: 10px;
  z-index: 1000;
  font-size: 14px;
  color: whitesmoke;
}
.timer-controls {
  display: flex;
  gap: 10px;
  align-items: center;
}
.react-datepicker__input-container input,
.react-datepicker__input-container input:active {
  width: 100% !important;
  outline: none !important;
  border: 1px solid #ccc !important;
  padding: 8px !important;
  margin: 3px 0 !important;
  border-radius: 5px;
}
