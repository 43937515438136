footer {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #205393;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  padding: 10px;
  z-index: 999;
  position: fixed;
  bottom: 0;
}
