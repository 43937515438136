.folder_doc {
  margin: 10px 0;
}

.gap {
  margin: 0 20px 0 10px;
  display: flex;
  gap: 10px;
}

.document {
  display: block;
  text-align: center;
}
.document_breadcrumb {
  cursor: pointer;
}
.document p {
  word-wrap: break-word;
}

.table-container {
  display: block;
  max-height: 60vh; /* Adjust this height as needed */
  overflow-y: auto;
  width: 100%;
  margin: 10px 0
}
.table {
  width: 100%;
  border-collapse: collapse;
}

.table th,
.table td {
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.table th {
  position: sticky;
  top: 0;
  background: #f9f9f9; /* Adjust the background as needed */
  z-index: 1; /* Ensure the header is above the body content */
}

