/* Breadcrumbs.css */
.breadcrumbs {
  padding: 0.5rem 1rem;
  background-color: #f8f9fa;
  border-bottom: 1px solid #dee2e6;
}

.breadcrumbs ul {
  list-style: none;
  display: flex;
  gap: 0.5rem;
  margin: 0;
  padding: 0;
}

.breadcrumbs li {
  display: flex;
  align-items: center;
}

.breadcrumbs a {
  color: #007bff;
  text-decoration: none;
}

.breadcrumbs span {
  color: #6c757d;
}
