.user_defined_page {
  margin-top: 25px;
  overflow: auto;
  height: calc(100vh - 40px);
}
.user_defined {
  background-color: rgb(249, 249, 249);
  padding: 16px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 16px;
  max-height: 300px;
  overflow: auto;
}
.button_wrapper {
  display: flex;
  gap: 15px;
  padding: 10px;
  margin-bottom: 30px;
}
.flex_wraper {
  display: flex;
  justify-content: flex-end;
}
.field-pading {
  padding: 10px 0;
}
.view-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: fixed;
  z-index: 1;
  width: calc(100vw - 295px);
  background: #fff;
  padding-right: 5px;
}

.formGroup {
  display: flex;
  width: calc(100% / 2 - 15px);
  justify-content: space-between;
  flex-direction: column;
}
.fieldInput {
  width: 80%;
  grid-template-columns: repeat(
    auto-fit,
    minmax(200px, 1fr)
  ); /* Adjust as needed */
  justify-items: space-between;
  align-items: stretch;
  gap: 10px; /* Adjust gap as needed */
}
.fieldInput label {
  width: 20%;
}

.w-100 {
  width: 100%;
}
