.button-end {
  display: flex;
  justify-content: flex-end;
}

.contact-form-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 1ch;
}

.street-flex {
  display: flex;
}

.button-end-popup {
  width: 100%;
  padding-top: 20px;
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}
