.button_client {
    display: inline-flex;
    justify-content: flex-start !important;
    width: 160px;
    font-weight: 500 !important;
  }
  .error-message{
    display: grid;
    place-items: center;
    color: red;
    margin-top: -319px;
    font-size: medium;
    font-weight: 700;
  }
  input{
    cursor:pointer;
  }
  .main-report-container{
    display: flex;
    flex-direction: column;
    gap: 20px;
    /* background-color: #f0f0f0; */
     height: calc(100vh - 285px);
     width: 100%;
     padding: 20px;
  }
  .report-component-section{
    display: flex;
    justify-content: space-between;
    gap: 10px;
    align-content: center;
  }