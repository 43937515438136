.close-button-end-parent{
    width: 100%;
    position: relative;
}

.close-button-end{
    cursor: pointer;
    position: absolute;
    right: -15px;
    top: -15px;
}